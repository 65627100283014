<template>
  <!-- eslint-disable -->
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Groupe_411"
    width="234.874"
    height="73.689"
    viewBox="0 0 234.874 73.689"
  >
    <g id="Groupe_408" transform="translate(103.391 7.828)">
      <g id="Groupe_406" transform="translate(0 0)">
        <path
          id="Tracé_403"
          d="M7170.576 2988.708a3.915 3.915 0 0 1 1.039 2.814v28.8a3.565 3.565 0 0 1-.963 2.636 3.427 3.427 0 0 1-2.535.963 3.36 3.36 0 0 1-2.485-.963 3.565 3.565 0 0 1-.963-2.636v-17.9l-7.2 13.539a5.629 5.629 0 0 1-1.547 1.927 3.557 3.557 0 0 1-3.979 0 5.469 5.469 0 0 1-1.572-1.927l-7.2-13.285v17.646a3.584 3.584 0 0 1-.964 2.611 3.715 3.715 0 0 1-5.02.025 3.561 3.561 0 0 1-.964-2.636v-28.8a3.908 3.908 0 0 1 1.04-2.814 3.577 3.577 0 0 1 2.713-1.091 4 4 0 0 1 3.7 2.587l10.294 19.522 10.242-19.522q1.37-2.587 3.651-2.587a3.58 3.58 0 0 1 2.713 1.091z"
          transform="translate(-7136.222 -2987.618)"
        ></path>
        <path
          id="Tracé_404"
          d="M7358.959 3023.724a3.7 3.7 0 0 1-.964-2.738v-28.345a3.358 3.358 0 0 1 3.7-3.7h17.95q3.753 0 3.753 3.043 0 3.093-3.753 3.093H7365.7v8.366h12.931q3.751 0 3.752 3.093 0 3.042-3.752 3.042H7365.7v8.975h13.944q3.753 0 3.753 3.093 0 3.043-3.753 3.043H7361.7a3.7 3.7 0 0 1-2.741-.965z"
          transform="translate(-7315.401 -2988.685)"
        ></path>
        <path
          id="Tracé_405"
          d="M7515.519 3023.724a3.7 3.7 0 0 1-.963-2.738v-28.345a3.357 3.357 0 0 1 3.7-3.7h17.951q3.751 0 3.752 3.043 0 3.093-3.752 3.093h-13.945v8.366h12.931q3.751 0 3.752 3.093 0 3.042-3.752 3.042h-12.931v8.975h13.945q3.751 0 3.752 3.093 0 3.043-3.752 3.043h-17.951a3.7 3.7 0 0 1-2.737-.965z"
          transform="translate(-7441.894 -2988.685)"
        ></path>
        <path
          id="Tracé_406"
          d="M7672.178 3023.927a3.908 3.908 0 0 1-1.064-2.89v-28.4a3.358 3.358 0 0 1 3.7-3.7h12.728q5.83 0 9.076 2.941t3.246 8.215q0 5.275-3.246 8.215t-9.076 2.941h-8.52v9.787a4.092 4.092 0 0 1-6.846 2.89zm14.352-18.66q5.931 0 5.933-5.121 0-5.172-5.933-5.172h-7.506v10.293z"
          transform="translate(-7568.382 -2988.685)"
        ></path>
      </g>
      <g id="Groupe_407" transform="translate(0 45.397)">
        <path
          id="Tracé_407"
          d="M7145.392 3224.112a.454.454 0 0 1 .12.325v9.191a.475.475 0 0 1-.112.325.4.4 0 0 1-.312.127.362.362 0 0 1-.3-.12.505.505 0 0 1-.1-.332v-7.836l-3.346 6.466a.5.5 0 0 1-.932 0l-3.375-6.41v7.779a.505.505 0 0 1-.1.332.362.362 0 0 1-.3.12.4.4 0 0 1-.318-.12.486.486 0 0 1-.105-.332v-9.191a.454.454 0 0 1 .12-.325.435.435 0 0 1 .332-.127.493.493 0 0 1 .423.325l3.8 7.285 3.742-7.285q.168-.324.423-.325a.435.435 0 0 1 .34.128z"
          transform="translate(-7136.222 -3223.985)"
        ></path>
        <path
          id="Tracé_408"
          d="M7196.388 3246.322a2.931 2.931 0 0 1-1.108-1.243 4.655 4.655 0 0 1 0-3.755 2.929 2.929 0 0 1 1.108-1.242 3.347 3.347 0 0 1 3.31 0 2.881 2.881 0 0 1 1.109 1.242 4.741 4.741 0 0 1 0 3.755 2.883 2.883 0 0 1-1.109 1.243 3.347 3.347 0 0 1-3.31 0zm3.324-1.045a3.893 3.893 0 0 0-.007-4.144 2.235 2.235 0 0 0-3.325 0 3.957 3.957 0 0 0-.014 4.144 2.013 2.013 0 0 0 1.666.72 2.053 2.053 0 0 0 1.68-.719z"
          transform="translate(-7183.617 -3236.637)"
        ></path>
        <path
          id="Tracé_409"
          d="M7242.9 3242.3v4a.445.445 0 0 1-.113.318.406.406 0 0 1-.311.12.413.413 0 0 1-.437-.438v-3.939a2.21 2.21 0 0 0-.41-1.482 1.641 1.641 0 0 0-1.3-.466 2.19 2.19 0 0 0-1.631.621 2.267 2.267 0 0 0-.614 1.666v3.6a.449.449 0 0 1-.112.318.41.41 0 0 1-.312.12.413.413 0 0 1-.437-.438v-6.184a.412.412 0 0 1 .438-.438.4.4 0 0 1 .3.113.42.42 0 0 1 .112.311v.889a2.292 2.292 0 0 1 .938-1 2.85 2.85 0 0 1 1.42-.346q2.469-.001 2.469 2.655z"
          transform="translate(-7217.828 -3236.637)"
        ></path>
        <path
          id="Tracé_410"
          d="M7300.623 3245.3a.368.368 0 0 1 .092.247q0 .311-.48.607a4.12 4.12 0 0 1-2.146.607 3.255 3.255 0 0 1-2.471-.946 3.6 3.6 0 0 1-.9-2.6 4.155 4.155 0 0 1 .4-1.87 2.987 2.987 0 0 1 1.13-1.257 3.136 3.136 0 0 1 1.673-.445 2.683 2.683 0 0 1 2.1.854 3.386 3.386 0 0 1 .769 2.351.535.535 0 0 1-.111.374.486.486 0 0 1-.367.12h-4.7a2.934 2.934 0 0 0 .678 1.977 2.338 2.338 0 0 0 1.793.677 2.762 2.762 0 0 0 1.017-.163 6.782 6.782 0 0 0 .876-.43 1 1 0 0 1 .438-.212.281.281 0 0 1 .209.109zm-4.285-4.327a2.849 2.849 0 0 0-.706 1.737h4.362a2.68 2.68 0 0 0-.564-1.744 1.9 1.9 0 0 0-1.511-.614 2.082 2.082 0 0 0-1.581.619z"
          transform="translate(-7264.273 -3236.637)"
        ></path>
        <path
          id="Tracé_411"
          d="M7337.885 3246.427a.4.4 0 0 1-.119.289.38.38 0 0 1-.275.12.388.388 0 0 1-.324-.169l-2.415-2.8-2.414 2.8a.418.418 0 0 1-.325.169.38.38 0 0 1-.275-.12.393.393 0 0 1-.119-.289.471.471 0 0 1 .127-.325l2.471-2.824-2.387-2.753a.494.494 0 0 1-.127-.311.408.408 0 0 1 .121-.3.376.376 0 0 1 .274-.12.457.457 0 0 1 .339.169l2.315 2.711 2.329-2.711a.447.447 0 0 1 .326-.169.379.379 0 0 1 .274.12.4.4 0 0 1 .12.29.469.469 0 0 1-.127.325l-2.387 2.753 2.472 2.824a.442.442 0 0 1 .126.321z"
          transform="translate(-7294.09 -3236.755)"
        ></path>
        <path
          id="Tracé_412"
          d="M7377.449 3240.089a2.929 2.929 0 0 1 1.072 1.257 4.379 4.379 0 0 1 .383 1.885 4.25 4.25 0 0 1-.383 1.857 2.837 2.837 0 0 1-2.668 1.673 2.768 2.768 0 0 1-1.439-.36 2.25 2.25 0 0 1-.918-1.052v3.487a.45.45 0 0 1-.113.318.406.406 0 0 1-.311.12.413.413 0 0 1-.437-.438v-8.725a.412.412 0 0 1 .438-.438.407.407 0 0 1 .311.12.45.45 0 0 1 .113.318v.946a2.253 2.253 0 0 1 .918-1.052 2.766 2.766 0 0 1 1.439-.36 2.884 2.884 0 0 1 1.595.444zm-.036 5.182a3.119 3.119 0 0 0 .6-2.041 3.256 3.256 0 0 0-.6-2.089 2.022 2.022 0 0 0-1.673-.749 2 2 0 0 0-1.658.734 3.98 3.98 0 0 0-.008 4.144 1.988 1.988 0 0 0 1.652.727 2.063 2.063 0 0 0 1.687-.726z"
          transform="translate(-7327.229 -3236.637)"
        ></path>
        <path
          id="Tracé_413"
          d="M7418.389 3245.3a.364.364 0 0 1 .092.247q0 .311-.48.607a4.116 4.116 0 0 1-2.146.607 3.252 3.252 0 0 1-2.471-.946 3.605 3.605 0 0 1-.9-2.6 4.142 4.142 0 0 1 .4-1.87 2.978 2.978 0 0 1 1.129-1.257 3.136 3.136 0 0 1 1.673-.445 2.68 2.68 0 0 1 2.1.854 3.386 3.386 0 0 1 .77 2.351.526.526 0 0 1-.113.374.483.483 0 0 1-.367.12h-4.7a2.939 2.939 0 0 0 .678 1.977 2.339 2.339 0 0 0 1.793.677 2.755 2.755 0 0 0 1.017-.163 6.713 6.713 0 0 0 .875-.43 1.011 1.011 0 0 1 .438-.212.282.282 0 0 1 .212.109zm-4.285-4.327a2.849 2.849 0 0 0-.706 1.737h4.363a2.68 2.68 0 0 0-.565-1.744 1.9 1.9 0 0 0-1.511-.614 2.085 2.085 0 0 0-1.585.619z"
          transform="translate(-7359.421 -3236.637)"
        ></path>
        <path
          id="Tracé_414"
          d="M7457 3239.853a.331.331 0 0 1 .114.282q0 .353-.425.4l-.423.042a2.054 2.054 0 0 0-1.491.72 2.3 2.3 0 0 0-.486 1.454v3.657a.436.436 0 0 1-.113.325.417.417 0 0 1-.31.113.447.447 0 0 1-.318-.113.424.424 0 0 1-.12-.325v-6.184a.425.425 0 0 1 .12-.325.448.448 0 0 1 .318-.113.4.4 0 0 1 .3.113.421.421 0 0 1 .112.311v.917a2.363 2.363 0 0 1 2.146-1.327l.2-.014a.6.6 0 0 1 .376.067z"
          transform="translate(-7392.502 -3236.747)"
        ></path>
        <path
          id="Tracé_415"
          d="M7479.733 3237.885a.323.323 0 0 1-.128.268.616.616 0 0 1-.409.085l-.395-.029a2.212 2.212 0 0 1-1.589-.656 2.642 2.642 0 0 1-.516-1.786v-3.756h-1.073a.376.376 0 0 1-.268-.092.321.321 0 0 1-.1-.247.346.346 0 0 1 .1-.254.361.361 0 0 1 .268-.1h1.073v-1.751a.436.436 0 0 1 .113-.324.417.417 0 0 1 .31-.113.444.444 0 0 1 .318.113.424.424 0 0 1 .12.324v1.751h1.708a.384.384 0 0 1 .283.1.344.344 0 0 1 .1.254.32.32 0 0 1-.1.247.4.4 0 0 1-.283.092h-1.708v3.8a1.843 1.843 0 0 0 .339 1.25 1.532 1.532 0 0 0 1.059.43l.381.028c.264.027.397.149.397.366z"
          transform="translate(-7410.141 -3228.142)"
        ></path>
        <path
          id="Tracé_416"
          d="M7527.185 3245.3a.365.365 0 0 1 .093.247c0 .207-.161.41-.48.607a4.119 4.119 0 0 1-2.146.607 3.254 3.254 0 0 1-2.472-.946 3.6 3.6 0 0 1-.9-2.6 4.155 4.155 0 0 1 .4-1.87 2.991 2.991 0 0 1 1.129-1.257 3.139 3.139 0 0 1 1.674-.445 2.679 2.679 0 0 1 2.1.854 3.382 3.382 0 0 1 .771 2.351.53.53 0 0 1-.113.374.483.483 0 0 1-.367.12h-4.7a2.929 2.929 0 0 0 .678 1.977 2.336 2.336 0 0 0 1.793.677 2.753 2.753 0 0 0 1.016-.163 6.6 6.6 0 0 0 .876-.43 1.009 1.009 0 0 1 .438-.212.281.281 0 0 1 .21.109zm-4.285-4.327a2.861 2.861 0 0 0-.706 1.737h4.363a2.675 2.675 0 0 0-.564-1.744 1.9 1.9 0 0 0-1.511-.614 2.086 2.086 0 0 0-1.582.619z"
          transform="translate(-7447.323 -3236.637)"
        ></path>
        <path
          id="Tracé_417"
          d="M7567.9 3242.3v4a.449.449 0 0 1-.113.318.408.408 0 0 1-.312.12.414.414 0 0 1-.437-.438v-3.939a2.216 2.216 0 0 0-.409-1.482 1.644 1.644 0 0 0-1.3-.466 2.188 2.188 0 0 0-1.631.621 2.267 2.267 0 0 0-.614 1.666v3.6a.445.445 0 0 1-.113.318.408.408 0 0 1-.311.12.411.411 0 0 1-.437-.438v-6.184a.411.411 0 0 1 .438-.438.4.4 0 0 1 .3.113.424.424 0 0 1 .112.311v.889a2.292 2.292 0 0 1 .938-1 2.846 2.846 0 0 1 1.419-.346q2.47-.001 2.47 2.655z"
          transform="translate(-7480.404 -3236.637)"
        ></path>
        <path
          id="Tracé_418"
          d="M7626.8 3240.089a2.94 2.94 0 0 1 1.074 1.257 4.408 4.408 0 0 1 .38 1.885 4.278 4.278 0 0 1-.38 1.857 2.843 2.843 0 0 1-2.669 1.673 2.772 2.772 0 0 1-1.44-.36 2.26 2.26 0 0 1-.918-1.052v3.487a.449.449 0 0 1-.112.318.41.41 0 0 1-.312.12.413.413 0 0 1-.437-.438v-8.725a.412.412 0 0 1 .438-.438.41.41 0 0 1 .312.12.45.45 0 0 1 .112.318v.946a2.264 2.264 0 0 1 .918-1.052 2.769 2.769 0 0 1 1.44-.36 2.884 2.884 0 0 1 1.594.444zm-.035 5.182a3.124 3.124 0 0 0 .6-2.041 3.261 3.261 0 0 0-.6-2.089 2.026 2.026 0 0 0-1.673-.749 2 2 0 0 0-1.659.734 3.977 3.977 0 0 0-.007 4.144 1.987 1.987 0 0 0 1.652.727 2.066 2.066 0 0 0 1.688-.726z"
          transform="translate(-7528.691 -3236.637)"
        ></path>
        <path
          id="Tracé_419"
          d="M7663.334 3246.322a2.935 2.935 0 0 1-1.107-1.243 4.644 4.644 0 0 1 0-3.755 2.933 2.933 0 0 1 1.107-1.242 3.35 3.35 0 0 1 3.312 0 2.877 2.877 0 0 1 1.107 1.242 4.73 4.73 0 0 1 0 3.755 2.879 2.879 0 0 1-1.107 1.243 3.35 3.35 0 0 1-3.312 0zm3.325-1.045a3.893 3.893 0 0 0-.007-4.144 2.235 2.235 0 0 0-3.325 0 3.959 3.959 0 0 0-.015 4.144 2.016 2.016 0 0 0 1.667.72 2.053 2.053 0 0 0 1.681-.719z"
          transform="translate(-7560.882 -3236.637)"
        ></path>
        <path
          id="Tracé_420"
          d="M7703.4 3246.322a2.918 2.918 0 0 1-1.114-1.243 4.537 4.537 0 0 1 .007-3.72 3.039 3.039 0 0 1 1.13-1.263 3.1 3.1 0 0 1 1.673-.452 3.888 3.888 0 0 1 1.045.141 2.856 2.856 0 0 1 .9.423 1.6 1.6 0 0 1 .36.332.538.538 0 0 1 .105.318.376.376 0 0 1-.085.254.265.265 0 0 1-.211.1.933.933 0 0 1-.452-.24 3.661 3.661 0 0 0-.734-.423 2.273 2.273 0 0 0-.89-.156 2.109 2.109 0 0 0-1.723.756 3.186 3.186 0 0 0-.621 2.082 3.073 3.073 0 0 0 .614 2.041 2.123 2.123 0 0 0 1.715.727 2.268 2.268 0 0 0 .9-.155 5.682 5.682 0 0 0 .783-.423c.085-.056.167-.108.247-.156a.416.416 0 0 1 .19-.071.284.284 0 0 1 .227.1.356.356 0 0 1 .085.24.471.471 0 0 1-.1.3 1.873 1.873 0 0 1-.382.325 2.973 2.973 0 0 1-.925.445 3.762 3.762 0 0 1-1.08.162 3.148 3.148 0 0 1-1.664-.444z"
          transform="translate(-7593.251 -3236.637)"
        ></path>
        <path
          id="Tracé_421"
          d="M7744.766 3229.647v4a.445.445 0 0 1-.112.317.409.409 0 0 1-.311.12.413.413 0 0 1-.438-.437v-3.947a2.216 2.216 0 0 0-.409-1.482 1.645 1.645 0 0 0-1.3-.466 2.19 2.19 0 0 0-1.631.621 2.267 2.267 0 0 0-.613 1.667v3.6a.45.45 0 0 1-.113.317.409.409 0 0 1-.312.12.413.413 0 0 1-.437-.437v-9.22a.413.413 0 0 1 .437-.437.408.408 0 0 1 .312.12.449.449 0 0 1 .113.317v3.883a2.258 2.258 0 0 1 .938-.974 2.854 2.854 0 0 1 1.4-.339q2.475.003 2.476 2.657z"
          transform="translate(-7623.304 -3223.985)"
        ></path>
        <path
          id="Tracé_422"
          d="M7783.962 3245.3a.364.364 0 0 1 .092.247q0 .311-.48.607a4.116 4.116 0 0 1-2.146.607 3.25 3.25 0 0 1-2.471-.946 3.6 3.6 0 0 1-.9-2.6 4.142 4.142 0 0 1 .4-1.87 2.978 2.978 0 0 1 1.129-1.257 3.135 3.135 0 0 1 1.672-.445 2.682 2.682 0 0 1 2.1.854 3.381 3.381 0 0 1 .77 2.351.53.53 0 0 1-.113.374.483.483 0 0 1-.367.12h-4.7a2.939 2.939 0 0 0 .678 1.977 2.339 2.339 0 0 0 1.793.677 2.759 2.759 0 0 0 1.017-.163 6.721 6.721 0 0 0 .876-.43 1.007 1.007 0 0 1 .437-.212.284.284 0 0 1 .213.109zm-4.285-4.327a2.849 2.849 0 0 0-.706 1.737h4.362a2.675 2.675 0 0 0-.564-1.744 1.9 1.9 0 0 0-1.512-.614 2.083 2.083 0 0 0-1.58.619z"
          transform="translate(-7654.783 -3236.637)"
        ></path>
      </g>
    </g>
    <g id="Groupe_410" transform="translate(0 0)">
      <g id="Groupe_409" transform="translate(0 29.995)">
        <path
          id="Tracé_423"
          d="M6683.326 3131.137a15.88 15.88 0 0 1-15.957 15.593h-54.942a14.46 14.46 0 0 1-14.529-14.216 14.315 14.315 0 0 1 6.394-12.046 15.274 15.274 0 0 1 9.992-16.974c.056-.021.112-.04.168-.059l.018-.006a2.8 2.8 0 0 1 .8-.117 2.746 2.746 0 0 1 .771 5.382 9.8 9.8 0 0 0-5.979 12.29 2.745 2.745 0 0 1-1.472 3.357 8.84 8.84 0 0 0 3.644 16.894h55.366a10.239 10.239 0 0 0 .836-20.443 2.748 2.748 0 0 1-2.526-2.7 11.072 11.072 0 0 0-1.774-5.881 11.3 11.3 0 0 0-4.294-3.9 2.764 2.764 0 0 1 2.088-5.113c.2.094.4.194.6.3l.012.008a16.7 16.7 0 0 1 8.672 12.182 15.712 15.712 0 0 1 12.121 15.446z"
          transform="translate(-6597.897 -3103.037)"
        ></path>
      </g>
      <path
        id="Tracé_424"
        d="M6861.9 3147.091v6.6a3.143 3.143 0 1 1-6.285 0v-6.6a3.143 3.143 0 0 1 6.285 0z"
        class="cls-1"
        transform="translate(-6806.114 -3106.096)"
      ></path>
      <path
        id="Tracé_425"
        d="M6755.1 2950.351v28.249a3.992 3.992 0 0 1-.118 1.008 2.8 2.8 0 0 1-.744 1.348 3.053 3.053 0 0 1-2.266.862 3 3 0 0 1-2.22-.862 3.179 3.179 0 0 1-.86-2.356v-18.51l-3.253 6.115-2.6 4.9-.579 1.086a5.027 5.027 0 0 1-1.381 1.722 3.185 3.185 0 0 1-3.558 0 4.877 4.877 0 0 1-1.405-1.722l-.556-1.026-2.652-4.893-3.226-5.954v18.282a3.2 3.2 0 0 1-.863 2.333 3.314 3.314 0 0 1-4.484.022 3.179 3.179 0 0 1-.86-2.356v-28.253a3.5 3.5 0 0 1 .928-2.514 3.192 3.192 0 0 1 2.424-.976 3.572 3.572 0 0 1 3.309 2.311l8.386 15.9.814 1.544.812-1.547 8.343-15.9a3.663 3.663 0 0 1 3.261-2.311 3.187 3.187 0 0 1 2.424.976 3.488 3.488 0 0 1 .924 2.522z"
        transform="translate(-6699.35 -2946.861)"
      ></path>
      <path
        id="Tracé_426"
        d="M6861.9 3147.091v6.6a3.143 3.143 0 1 1-6.285 0v-6.6a3.143 3.143 0 0 1 6.285 0z"
        class="cls-2"
        transform="translate(-6806.114 -3106.096)"
      ></path>
      <path
        id="Tracé_427"
        d="M6795.826 3108.736v4.086a3.143 3.143 0 1 1-6.286 0v-4.086a3.143 3.143 0 1 1 6.286 0z"
        class="cls-3"
        transform="translate(-6752.733 -3075.107)"
      ></path>
      <path
        id="Tracé_428"
        fill="#ffa4af"
        d="M6795.734 3177.793v6.6a3.143 3.143 0 1 1-6.286 0v-6.6a3.143 3.143 0 1 1 6.286 0z"
        transform="translate(-6752.659 -3130.901)"
      ></path>
      <path
        id="Tracé_429"
        d="M6861.9 3229.24v1.571a3.143 3.143 0 1 1-6.285 0v-1.571a3.143 3.143 0 1 1 6.285 0z"
        class="cls-3"
        transform="translate(-6806.114 -3172.466)"
      ></path>
      <path
        id="Tracé_430"
        d="M6729.753 3147.091v6.6a3.143 3.143 0 1 1-6.285 0v-6.6a3.143 3.143 0 0 1 6.285 0z"
        class="cls-1"
        transform="translate(-6699.35 -3106.096)"
      ></path>
      <path
        id="Tracé_431"
        d="M6729.753 3147.091v6.6a3.143 3.143 0 1 1-6.285 0v-6.6a3.143 3.143 0 0 1 6.285 0z"
        class="cls-2"
        transform="translate(-6699.35 -3106.096)"
      ></path>
      <path
        id="Tracé_432"
        d="M6729.753 3229.24v1.571a3.143 3.143 0 1 1-6.285 0v-1.571a3.143 3.143 0 1 1 6.285 0z"
        class="cls-3"
        transform="translate(-6699.35 -3172.466)"
      ></path>
      <path
        id="Tracé_433"
        d="M6931.866 3097.188c-.2-.108-.4-.208-.6-.3a2.848 2.848 0 0 1 .6.3z"
        transform="translate(-6867.241 -3068.073)"
      ></path>
    </g>
  </svg>
</template>

<script>
export default {
  name: "MeepIconLogoMeep",
};
</script>

<style scoped>
.cls-1 {
  fill: #fff;
}
.cls-2 {
  fill: #8bdcfc;
}
.cls-3 {
  fill: #a899ff;
}
</style>
